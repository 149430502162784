// Entry point for the build script in your package.json
// import "@fortawesome/fontawesome-free/js/all.js"
import "@fortawesome/fontawesome-pro/js/fontawesome" // TODO BUY!
import "@fortawesome/fontawesome-pro/js/brands" // TODO BUY!
import "@fortawesome/fontawesome-pro/js/regular" // TODO BUY!
import Rails from "@rails/ujs"

import "@hotwired/turbo-rails"

import * as bootstrap from "bootstrap"

import "./shop/vanilla_controllers/shop_controller"

Rails.start()
