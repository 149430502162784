import lozad from "lozad";

document.addEventListener("turbo:load", () => {

  const observer = lozad('.lozad', {
      rootMargin: '10px 0px',
      threshold: 0.1,
      enableAutoReload: true
  });

  observer.observe();
  

});
